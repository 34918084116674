export default function redirectHelperPage(sectionString) {
    switch (sectionString) {
        // document Edit - Add
        case 'documentEdit-header':
            return window.open('https://docs.google.com/document/d/1a7J6gE2Oen6mbqpr4cMck9SmkTgBMmKjEfTOPZbW4WU/edit#heading=h.mf63vnd71iob', '_blank');
        case 'documentEdit-section1':
            return window.open('https://docs.google.com/document/d/1a7J6gE2Oen6mbqpr4cMck9SmkTgBMmKjEfTOPZbW4WU/edit#heading=h.clnhz3f575zr', '_blank');
        case 'documentEdit-section2':
            return window.open('https://docs.google.com/document/d/1a7J6gE2Oen6mbqpr4cMck9SmkTgBMmKjEfTOPZbW4WU/edit#heading=h.kx7pdwf98wgw', '_blank');
        case 'documentEdit-section3':
            return window.open('https://docs.google.com/document/d/1a7J6gE2Oen6mbqpr4cMck9SmkTgBMmKjEfTOPZbW4WU/edit#heading=h.qsp5s8sofk7r', '_blank');
        case 'documentEdit-section4':
            return window.open('https://docs.google.com/document/d/1a7J6gE2Oen6mbqpr4cMck9SmkTgBMmKjEfTOPZbW4WU/edit#heading=h.7qdw8ug41m6', '_blank');

        // documents overview 
        case 'documentsOverview-header':
            return window.open('https://docs.google.com/document/d/1a7J6gE2Oen6mbqpr4cMck9SmkTgBMmKjEfTOPZbW4WU/edit#heading=h.xa9hzccnm8mo', '_blank');

        // export Page
        case 'exportPage-header':
            return window.open('https://docs.google.com/document/d/1a7J6gE2Oen6mbqpr4cMck9SmkTgBMmKjEfTOPZbW4WU/edit#heading=h.y53ct2stqf8e', '_blank');

        // overviewPage Topic
        case 'overviewPage-header':
            return window.open('https://docs.google.com/document/d/1a7J6gE2Oen6mbqpr4cMck9SmkTgBMmKjEfTOPZbW4WU/edit#heading=h.kg0mvjl059ci', '_blank');

        // Topic
        case 'topicPage-header':
            return window.open('https://docs.google.com/document/d/1a7J6gE2Oen6mbqpr4cMck9SmkTgBMmKjEfTOPZbW4WU/edit#heading=h.7eomjf130w3m', '_blank');

        // programOverview
        case 'programOverview-header':
            return window.open('https://docs.google.com/document/d/1a7J6gE2Oen6mbqpr4cMck9SmkTgBMmKjEfTOPZbW4WU/edit#heading=h.13spadxwxgbt', '_blank');

        // programEdit
        case 'programEdit-header':
            return window.open('https://docs.google.com/document/d/1a7J6gE2Oen6mbqpr4cMck9SmkTgBMmKjEfTOPZbW4WU/edit#heading=h.tpregv8wo48x', '_blank');
        case 'programEdit-section1':
            return window.open('https://docs.google.com/document/d/1a7J6gE2Oen6mbqpr4cMck9SmkTgBMmKjEfTOPZbW4WU/edit#heading=h.9utnynhkeq9c', '_blank');
        case 'programEdit-section2':
            return window.open('https://docs.google.com/document/d/1a7J6gE2Oen6mbqpr4cMck9SmkTgBMmKjEfTOPZbW4WU/edit#heading=h.1atxiax6hdeu', '_blank');
        case 'programEdit-section3':
            return window.open('https://docs.google.com/document/d/1a7J6gE2Oen6mbqpr4cMck9SmkTgBMmKjEfTOPZbW4WU/edit#heading=h.xn84du63w1bm', '_blank');
        case 'programEdit-section4':
            return window.open('https://docs.google.com/document/d/1a7J6gE2Oen6mbqpr4cMck9SmkTgBMmKjEfTOPZbW4WU/edit#heading=h.f6trbx76bcfn', '_blank');

        // CompanyEdit
        case 'company-header':
            return window.open('https://docs.google.com/document/d/1a7J6gE2Oen6mbqpr4cMck9SmkTgBMmKjEfTOPZbW4WU/edit#heading=h.px34v85ievaw', '_blank');
        case 'companyTopic-Information':
            return window.open('https://docs.google.com/document/d/1a7J6gE2Oen6mbqpr4cMck9SmkTgBMmKjEfTOPZbW4WU/edit#heading=h.3i6zevonjsou', '_blank');
        case 'company-Analyses':
            return window.open('https://docs.google.com/document/d/1a7J6gE2Oen6mbqpr4cMck9SmkTgBMmKjEfTOPZbW4WU/edit#heading=h.5er883vxd9x','_blank');


        default:
            return null;
    }
}