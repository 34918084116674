<template>
    <Button 
        @click="openCreateCompany" 
        icon="pi pi-plus" 
        class="p-button-success p-button-rounded me-3" 
    />
    <Dialog 
        v-model:visible="openDialog" 
        :style="{ width: '90%' }" 
        header="Create Company" 
        :modal="true"
        class="p-fluid container-fluid"
    >
        <div class="row">
            <div class="col-12">
                <div class="field my-3">
                    <label for="title">Name</label>
                    <InputText 
                        id="name" 
                        v-model="newCompanyForm.name"
                        @blur="v$.newCompanyForm.name.$touch"
                        required="true"
                        autofocus
                        :class="{'p-invalid': !newCompanyForm.name}"
                    />
                    <small 
                        class="p-error" 
                        v-if="!newCompanyForm.name"
                    > 
                    Name is required
                    </small>
                </div>
            </div>
        </div>

        <!-- Usability Update 04-03-2024 Hide Selected Companies -->
        <!-- <div class="row">
            <div class="col-12">
                <label for="selectedCompany">
                    Is <strong> Selected </strong> Company <InputSwitch v-model="newCompanyForm.hasPrioritary" style="margin-left: 0.7rem;"/>  
                </label>
            </div>
        </div> -->

        <!-- company Type, country number of employees  -->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-4">
                <div class="field my-3">
                    <label for="CompanyType">Company Type </label>
                    <Dropdown 
                        v-model="newCompanyForm.companyTypeId"
                        :options="companyTypes"
                        option-label="name"
                        option-value="companyTypeId"
                        placeholder="Company Types"
                        :class="{'p-invalid':!newCompanyForm.companyTypeId}"
                        :filter="true"
                        :loading="loadingCompanyTypes"
                    />

                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-4">
                <div class="field my-3">
                    <label for="Country">Country</label>
                    <Dropdown 
                        v-model="newCompanyForm.countryId" 
                        :options="countries" 
                        optionLabel="name"
                        optionValue="countryId" 
                        placeholder="Country"
                        :filter="true"
                        :loading="loadingCountries">
                    </Dropdown>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-4">
                <div class="field my-3">
                    <label for="numberEmployees">Number of employees</label>
                    <InputNumber 
                        id="numberEmployees" 
                        v-model="newCompanyForm.numberEmployees" 
                        autofocus
                        suffix=" employees" 
                    />
                </div>
            </div>
        </div>
        <!-- address  -->
        <!-- <div class="row">
            <div class="col-12 ">
                <div class="field my-3">
                    <label for="address">Address</label>
                    <InputText 
                        id="address" 
                        v-model="newCompanyForm.address" 
                        autofocus 
                    />
                </div>
            </div>
        </div> -->

        <!-- notes -->
        <div class="row">
            <div class="col-12 ">
                <label for="description">Description</label>
                <div class="field my-3">
                    <Textarea 
                        style="width:100%" 
                        id="description" 
                        v-model="newCompanyForm.description"
                        rows="5"
                        cols="30"
                    />

                </div>
            </div>

        </div>


        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="closeDialog()"   />
            <Button 
                label="Save" 
                icon="pi pi-check" 
                class="p-button-success ml-3"  
                :loading="createIsLoading" 
                :disabled="this.v$.newCompanyForm.$invalid || createIsLoading" @click="createCompany" 
            />
        </template>
    </Dialog>

</template>

<script>

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

// import { useToast } from 'vue-toastification';
// const toast = useToast();

export default {
    name: 'DocumentCreateCompany',
    components: {
        Dialog,
        Button,
        InputText,
        Dropdown,
        InputNumber,
        Textarea,
    },
    props: ['countries','company'],
    emits: ['addLastCompany'],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            loading:false,
            loadingCompanyTypes:false,
            loadingCountries:false,
            createIsLoading: false, 
            openDialog: false,
            newCompanyForm: {
                hasPrioritary: false,
                name: null, 
                companyTypeId: null,
                countryId: null,
                numberEmployees: null,
                address:'not Assigned',
                description: null,
            }
        }
    },
    validations() {
        return {
            newCompanyForm: {
                name: { required },
                companyTypeId: { required },
            }
        }
    },
    async mounted(){
        await this.getCompanyTypes()
    },
    computed:{
        companyTypes(){
            return this.$store.getters.obtainCompanyTypes;
        }
    },
    methods: {
        openCreateCompany() {
            this.openDialog = true
        },
        async getCompanyTypes(){
            try {
                this.loadingCompanyTypes = true;
                await this.$store.dispatch('obtainCompanyTypes');
                this.loadingCompanyTypes = false;
            } catch (error) {
                this.loadingCompanyTypes= true;
                this.loadingCompanyTypes= false;
            }

        },
        async createCompany(){
            try {
                this.createIsLoading = true;
                await this.$store.dispatch('addNewCompany', this.newCompanyForm);
                this.$emit('addLastCompany')
                this.clearDialog()
                this.closeDialog();
            } catch (error) {
                this.createIsLoading = true;
                this.createIsLoading = false;
            }
        },
        closeDialog(){
            this.openDialog = false
        },
        clearDialog(){
            this.newCompanyForm.name = null
            this.newCompanyForm.companyTypeId = null
            this.newCompanyForm.countryId = null
            this.newCompanyForm.numberEmployees = null
            // this.newCompanyForm.address = null
            this.newCompanyForm.description = null
        },
    },
    watch: {

    },
}
</script>